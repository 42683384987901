import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation, QueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { API_URL } from '../config';
import { Customer, CreateCustomer, CustomerPriceRules, FormDuplicateRule, CustomerRuleDuplication, Opts, UpdateCustomer } from 'types';

const CUSTOMER_QUERY_KEY = 'customer';
const CUSTOMER_PRICE_RULE_QUERY_KEY = 'customer-price-rule';
const RULE_DUPLICATION_CUSTOMER_QUERY_KEY = 'rule_duplication_customer_query';
const CUSTOMER_API_URL = '/v1/customer';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  customers: {
    get: (id: number) => axios.get(`${API_URL}${CUSTOMER_API_URL}/${id}`),
    getAllPriceRules: (id: number) => axios.get(`${API_URL}${CUSTOMER_API_URL}/${id}/price-prediction-rules/`),
    list: (filters: string, size?: number, page?: number, ordering?: string) => axios.get(`${API_URL}${CUSTOMER_API_URL}/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
    ruleDuplicationCustomerQuery: (id:number) => axios.get(`${API_URL}${CUSTOMER_API_URL}/${id}/rule_duplication_customer_query/`),
    create: (payload: CreateCustomer) => axios.post(`${API_URL}${CUSTOMER_API_URL}/`, payload),
    update: ({ id, customer_name, customer_slug, contact_email, contact_name, contact_phone, profit_center, nfi_pricing_contact, cost_type, default_fuel_price_delay, auto_response, notes, channel_id, tms, quote_expiration_period }: UpdateCustomer) =>
      axios.patch(`${API_URL}${CUSTOMER_API_URL}/${id}/`, { customer_name, customer_slug, contact_email, contact_name, contact_phone, profit_center, nfi_pricing_contact, cost_type, default_fuel_price_delay, auto_response, notes, channel_id, tms, quote_expiration_period}),
    delete: (id: number) => axios.delete(`${API_URL}${CUSTOMER_API_URL}/${id}`),
    bulkDelete: (payload: { ids: number[] }) => axios.delete(`${API_URL}${CUSTOMER_API_URL}/bulk_delete/`, {data: payload}),
    updateAllPriceRules: ({ id, price_prediction_rules}: CustomerPriceRules) =>
      axios.patch(`${API_URL}${CUSTOMER_API_URL}/${id}/price-prediction-rules/`, { price_prediction_rules }),
    merge: (payload: {target_customer: number, source_customers: number[]}) => axios.put(`${API_URL}${CUSTOMER_API_URL}/merge/`, payload),
    duplicatePriceRules: ({ customerId, target_customers }: FormDuplicateRule) => axios.post(`${API_URL}${CUSTOMER_API_URL}/${customerId}/duplicate-price-prediction-rules/`, { target_customers })
  }
};

export const useGetCustomersQuery = (filters?: string, size?: number, page?: number, ordering = 'customer_name') => {
  return useQuery(
    [ CUSTOMER_QUERY_KEY, { filters, size, page, ordering }],
    () => api.customers.list(filters, size, page, ordering),
    { enabled: filters ? !!filters : true, keepPreviousData: true }
  );
};

export const useGetCustomerById = (customerId: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<Customer>>([ CUSTOMER_QUERY_KEY, customerId ], () => api.customers.get(customerId), { ...queryOptions, enabled: !!customerId, });
};

export const useGetAllPriceRulesById = (customerId: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<CustomerPriceRules>>([ CUSTOMER_PRICE_RULE_QUERY_KEY, customerId ], () => api.customers.getAllPriceRules(customerId), { ...queryOptions, enabled: !!customerId, });
};

export const useRuleDuplicationCustomerQuery = (customerId: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<CustomerRuleDuplication[]>>([ RULE_DUPLICATION_CUSTOMER_QUERY_KEY, customerId ], () => api.customers.ruleDuplicationCustomerQuery(customerId), { ...queryOptions });
};

export const useCreateCustomerMutation = (queryClient: QueryClient, opts: Opts<unknown, {customer_name?: string[], customer_slug?: string[]}>) =>
  useMutation(api.customers.create, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CUSTOMER_QUERY_KEY);
    },
    onError: (error: AxiosError<{customer_name?: string[], customer_slug?: string[]}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useUpdateCustomerMutation = (queryClient: QueryClient, opts: Opts<unknown, {customer_name?: string[], customer_slug?: string[]}>) =>
  useMutation(api.customers.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOMER_QUERY_KEY);
      opts.onSuccess();
    },
    onError: (error: AxiosError<{customer_name?: string[], customer_slug?: string[]}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useUpdateCustomerPriceRulesMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.customers.updateAllPriceRules, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CUSTOMER_PRICE_RULE_QUERY_KEY);
    },
    onError: (error: AxiosError<unknown>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDeleteCustomerMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.customers.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CUSTOMER_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDeleteCustomersMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.customers.bulkDelete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CUSTOMER_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useMergeCustomersMutation = (queryClient: QueryClient, opts: Opts<unknown, {message: string}>) =>
  useMutation(api.customers.merge, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CUSTOMER_QUERY_KEY);
    },
    onError: (error: AxiosError<{message: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDuplicatePriceRulesMutation = (queryClient: QueryClient, opts: Opts<unknown, {message: string}>) =>
  useMutation(api.customers.duplicatePriceRules, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(RULE_DUPLICATION_CUSTOMER_QUERY_KEY);
    },
    onError: (error: AxiosError<{message: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });
