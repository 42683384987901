import React from 'react';
import { Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { ACTIVITY_TYPES, CURRENCY_TYPE, QUOTE_SOURCE_LABEL } from 'enums';
import { SadStates, WidgetNoResultsState } from 'components';
import { formatDateToDetailPageFormat, formatTime } from 'helpers';
import { useQuoteActivitesQuery } from 'services';
import { QuoteActivity as QuoteActivityType, QuoteDetails } from 'types';

import { useStyles } from './QuoteActivity.css';

type QuoteActivityProps = {
  quote: QuoteDetails
};

export const QuoteActivity = ({ quote } : QuoteActivityProps) => {
  const classes = useStyles();

  const { isLoading, isSuccess, isError, data: activityData }= useQuoteActivitesQuery(quote.id);
  const { data: activities } = activityData || {};

  const generateActivityMessage = (activity: QuoteActivityType, classes: ClassNameMap<'logData' | 'dataTimeData'>) => {

    switch (activity.activity_type) {
      case ACTIVITY_TYPES.CREATE_QUOTE:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Created by { activity.user_display_name }
          </Typography>
        );
      case ACTIVITY_TYPES.UPDATE_PRICE:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Updated by {activity.user_display_name} to {activity.custom_fields.currency === CURRENCY_TYPE.CAD ? 'C$' : '$' }{Math.round(activity.custom_fields.price)}
          </Typography>
        );
      case ACTIVITY_TYPES.MARK_QUOTE_WON:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Marked as Won by { activity.user_display_name }
          </Typography>
        );
      case ACTIVITY_TYPES.MARK_QUOTE_LOST:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Marked as Lost by { activity.user_display_name } { activity.custom_fields.lost_reason === null ? '' : `Lost reason: ${activity.custom_fields.lost_reason}` }
          </Typography>
        );
      case ACTIVITY_TYPES.MARK_QUOTE_RESPONDED:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Marked as Responded by {activity.user_display_name} {activity.custom_fields.price && <>(quoted price: {activity.custom_fields.currency === CURRENCY_TYPE.CAD ? 'C$' : '$'}{Math.round(activity.custom_fields.price)}) </>}
          </Typography>
        );
      case ACTIVITY_TYPES.MARK_QUOTE_REJECTED:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Marked as Rejected by {activity.user_display_name} Rejection reason: {activity.custom_fields.rejection_reason}
          </Typography>
        );
      case ACTIVITY_TYPES.RESPOND_BY_EMAIL:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Quoted by {activity.user_display_name}, sent to {activity.custom_fields.recipient_email}, {activity.custom_fields.price && <>for {activity.custom_fields.currency === CURRENCY_TYPE.CAD ? 'C$' : '$'}{Math.round(activity.custom_fields.price)}</>}
          </Typography>
        );
      case ACTIVITY_TYPES.RESPOND_BY_API:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Quoted by {activity.user_display_name}, sent to {activity.custom_fields.customer} ({`${QUOTE_SOURCE_LABEL[quote.quote_source]} Shipper`}) {activity.custom_fields.price && <> for {activity.custom_fields.currency === CURRENCY_TYPE.CAD ? 'C$' : '$'}{Math.round(activity.custom_fields.price)}</>}
          </Typography>
        );
      case ACTIVITY_TYPES.REJECT_BY_EMAIL:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Rejected by {activity.user_display_name}, sent to {activity.custom_fields.recipient_email} Rejection reason: {activity.custom_fields.rejection_reason}
          </Typography>
        );
      case ACTIVITY_TYPES.REJECT_BY_API:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Rejected by {activity.user_display_name}, sent to {activity.custom_fields.customer} (BY Shipper) Rejection reason: {activity.custom_fields.rejection_reason}
          </Typography>
        );
      case ACTIVITY_TYPES.UPDATE_QUOTE:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Updated by {activity.user_display_name}
          </Typography>
        );
      case ACTIVITY_TYPES.DUPLICATE_QUOTE:
        return (
          <Typography className={classes.logData}>
            <span className={classes.dataTimeData}> {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)} </span>
            Created by {activity.user_display_name} ( duplicate of {activity.custom_fields.original_quote} )
          </Typography>
        );
    }
  };

  return (
    <SadStates
      states={[
        {
          when: isSuccess && activities.length === 0,
          render: () => <WidgetNoResultsState message='No activities.'/>
        },
        {
          when: isError,
          render: () => <WidgetNoResultsState message='Something went wrong when trying to get quote activities.'/>
        },
      ]}>
      <Typography className={classes.scrollableContent}>
        {isLoading && <Typography className={classes.logData}>Loading events...</Typography>}
        {activities?.map((activity: QuoteActivityType, index: number) => {
          return <Typography key={index} className={classes.record}>{generateActivityMessage(activity, classes)}</Typography>;
        })}
      </Typography>
    </SadStates>
  );
};