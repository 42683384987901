import React, { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Box, FormControl, FormHelperText } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Field, FieldProps, Form, Formik } from 'formik';

import { ActionButton, NSelect, UploadFile } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORIES, FILE_CATEGORY, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, UPLOAD_CSV_XLSX_FILE_TITLE } from 'enums';
import { getUploadAwardMetaDataInitialValues } from 'helpers';
import { UploadAwardMetaData } from 'pages/UploadAwardPage/components/UploadAwardMetaData/UploadAwardMetaData';
import { useGetCustomersQuery, useImportFileMutation } from 'services';
import { Customer, QueryResult, UploadFileWithMetaData } from 'types';
import { uploadFileWithMetaDataValidation } from 'validations';

import { useStyle } from './UploadFileModal.css';

type UploadFileModalProps = {
  category?: FILE_CATEGORY,
  onCancel: () => void,
  onUploadSuccess?: () => void
}

export const UploadFileModal = ({ category, onCancel, onUploadSuccess }: UploadFileModalProps) => {
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>();
  const queryClient = useQueryClient();

  const initialValues = {
    file: null as File,
    category: category ? [category] : [],
    ...getUploadAwardMetaDataInitialValues(),
  };

  const { isLoading: isLoadingCustomer, data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const customers = customerData?.data?.map((c: Customer) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const { mutate: importFile, isLoading: isImportingFile } = useImportFileMutation({
    onSuccess: () => {
      onUploadSuccess && onUploadSuccess();
      onCancel();
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
    },
    onError: () => {
      onCancel();
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  }, queryClient);

  const fillWithMetaData = (formData: FormData, values: UploadFileWithMetaData) => {
    switch (values.category[0]) {
      case FILE_CATEGORY.AWARD:
        formData.set('name', values.name);
        formData.append('customer', values.customer);
        formData.append('region', values.region);
        formData.append('effective_date', format(new Date(values.effective_date), 'yyyy-MM-dd'));
        formData.append('expiration_date', format(new Date(values.expiration_date), 'yyyy-MM-dd'));
    }
  };

  const handleFileUpload = (values: UploadFileWithMetaData) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.file.name);
    formData.append('category', String(values.category[0]));
    formData.append('type', values.file.type);

    fillWithMetaData(formData, values);

    importFile(formData);
  };

  return (
    <Formik
      initialValues= {initialValues}
      validationSchema={uploadFileWithMetaDataValidation}
      validateOnMount
      onSubmit={handleFileUpload}>
      {({ values, errors, isValid, setFieldValue, setValues }) => (
        <Form>
          <Box>
            <UploadFile
              id='file'
              accept='.csv'
              className={classes.inputArea}
              errors={errors}
              inputRef={inputRef}
              title={UPLOAD_CSV_XLSX_FILE_TITLE}
              values={values}
              setFieldValue={setFieldValue} />
            {!category &&
              <Box className={classes.formControl}>
                <Field name='category'>
                  {({ form } : FieldProps) => (
                    <>
                      <FormControl className={classes.widthMax} error={!!form.errors['category']}>
                        <NSelect
                          isError={!!errors.category}
                          showChips={false}
                          variant='secondary'
                          placeholder='Choose Category'
                          label='File Category'
                          options={FILE_CATEGORIES.sort((a, b) => a.label.localeCompare(b.label))}
                          selectedValues={values.category}
                          setSelectedValues={(value) => {
                            // After category is changed metadata values should be reseted
                            const newValues = {
                              'file': values['file'],
                              'category': value as Array<FILE_CATEGORY>,
                              ...getUploadAwardMetaDataInitialValues()
                            };
                            setValues(newValues);
                          }} />
                        <FormHelperText className={classes.validationError}>{(form.errors as Record<string, string>)?.category || ' '}</FormHelperText>
                      </FormControl>
                      {values.category[0] === FILE_CATEGORY.AWARD &&
                        <UploadAwardMetaData
                          direction='column'
                          customers={customers}
                          errors={errors}
                          isLoadingCustomer={isLoadingCustomer}
                          values={values}
                          setFieldValue={setFieldValue} />}
                    </>
                  )}
                </Field>
              </Box>}
            <Box className={classes.buttonContainer}>
              <ActionButton className={classes.blueText} text='Cancel' variant='secondary' colorVariant='white' handleClick={onCancel} />
              <ActionButton type='submit' isLoading={isImportingFile} disabled={!isValid || isImportingFile} text='Confirm' />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};