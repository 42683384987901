import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { FormikProps } from 'formik';

import { ConfirmationDialog, FormModalContent, SelectField } from 'components';
import { useDuplicatePriceRulesMutation, useRuleDuplicationCustomerQuery } from 'services';
import { FormCustomer, FormValuesDuplicateRule } from 'types';
import { getShipperPriceRulesValidationSchema } from './validation';

import { ModalComponent } from '../../../components/Modals/ModalComponent/ModalComponent';

type CustomerDuplicatePriceRulesProps = {
  customerId: number,
  onCancel: () => void,
  onDuplicateSuccess: () => void
};

export const CustomerDuplicatePriceRulesModal = ({ customerId, onCancel, onDuplicateSuccess }: CustomerDuplicatePriceRulesProps) => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<FormCustomer>>();

  const [ showConfirmDialog, setShowConfirmDialog ] = useState(false);
  const [ targetCustomers, setTargetCustomers ] = useState<number[]>([]);
  const [ confirmMessage, setConfirmMessage ] = useState('');

  const { isLoading: isLoadingCustomers, data: ruleDuplicationCustomerQueryData } = useRuleDuplicationCustomerQuery(customerId);
  const ruleDuplicationCustomers = ruleDuplicationCustomerQueryData?.data?.map((customer) => {
    return { id: customer.id.toString(), label: customer.customer_name, hasRules: customer.has_price_prediction_rules };
  });

  const {mutate: duplicatePriceRules, isLoading: isDuplicatePriceRulesLoading} = useDuplicatePriceRulesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Price rules successfully duplicated!');
      // onCancel is called here because this method only sets the modal's opening flag to false
      // to close the modal after a successful action
      onCancel();
      onDuplicateSuccess();
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

  const initialValues: FormValuesDuplicateRule = {
    shippers: []
  };

  const onSubmit = (values: FormValuesDuplicateRule) => {
    const target_customers = [...values.shippers].map(Number);
    setTargetCustomers(target_customers);

    const customersWithRules = ruleDuplicationCustomers.filter(
      (customer) => customer?.hasRules && target_customers.includes(Number(customer?.id))
    ).map((customer) => customer?.label);

    if (customersWithRules.length > 0) {
      const customerNames = customersWithRules.join(', ');
      setConfirmMessage(`This action will ERASE and REPLACE rules of the following shippers: ${customerNames} with the rules of the selected shipper.\nProceed?`);
      setShowConfirmDialog(true);

    } else {
      duplicatePriceRules({ customerId, target_customers });
    }
  };

  return (
    <>
      <FormModalContent
        initialValues={initialValues}
        validationSchema={getShipperPriceRulesValidationSchema}
        validateOnChange={true}
        enableDivider={false}
        innerRef={formRef}
        isActionInProgress={isLoadingCustomers || isDuplicatePriceRulesLoading}
        primaryButtonLabel={'Save'}
        onCancel={onCancel}
        onSubmit={onSubmit}>
        {({ values }) => (
          <>
            <SelectField
              id='shippers'
              label='Choose shippers'
              defaultValue={values.shippers}
              options={ruleDuplicationCustomers}
              allOption='All shippers'
              multiple={true} />
          </>
        )}
      </FormModalContent>

      <ModalComponent message={confirmMessage} isOpen={showConfirmDialog} onCancel={() => setShowConfirmDialog(false)}>
        <ConfirmationDialog
          onSubmit={() => duplicatePriceRules({ customerId, target_customers: targetCustomers })}
          onCancel={() => setShowConfirmDialog(false)}
          primaryButtonLabel='Proceed'
          cancelButtonLabel='Cancel' />
      </ModalComponent>
    </>
  );
};