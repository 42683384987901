import React, { useCallback, useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import {
  AccessControl,
  ActionButton,
  FilterComponent,
  Layout,
  ModalComponent,
  TableComponent,
  UploadFileModal
} from 'components';
import { FILE_CATEGORY_LABELS, FILE_FILTER_CONFIG, FILE_STATUS, FILE_STATUS_LABELS, FILTER_PAGE_SOURCE, FILTER_SET_SOURCE } from 'enums';
import { addSelectAllOption, calculateNumberOfFilterChanges, getAppliedFilters, getPageAppliedFilters, setPageAppliedFilters, sortOptions } from 'helpers';
import { useGetFilesQuery, useGetUsersQuery } from 'services';
import { FileType, FileFilter, FilterParamsType } from 'types';
import { FILE_PERMISSIONS } from 'permissions';
import { FilesTableMenu } from './components/FilesTableMenu/FilesTableMenu';
import { FilesTableColumns } from './FilesTableConfig';

import { useStyles } from './FilesPage.css';

export const FilesPage = () => {
  const classes = useStyles();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.FILE);

  const mapSelectedFiltersToQuery = (filterParams: FilterParamsType, search: string) => {
    const uploadedBy = filterParams.uploadedBy as Array<string | number>;
    const category = filterParams.category as Array<string | number>;
    const status = filterParams.status as Array<string | number>;

    return {
      created_by: uploadedBy.includes('all') ? '' : uploadedBy.join(),
      status: status.includes('all') ? '' : status.join(),
      category: category.includes('all') ? '' : category.join(),
      search
    };
  };

  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(10);
  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ filterParams, setFilterParams ] = useState<FilterParamsType>(getAppliedFilters(appliedFiltersAndSearch));
  const [ searchFilters, setSearchFilters ] = useState<Record<string, string>>({
    uploadedBy: '',
  });
  const [ filter, setFilter ] = useState<FileFilter>(mapSelectedFiltersToQuery(filterParams, search) || {});
  const [ filterChangesNumber, setFilterChangesNumber ] = useState(0);
  const [ isUploadFileModalOpen, setIsUploadFileModalOpen ] = useState<boolean>(false);

  const { isFetching: isFilesFetching, isSuccess, isError, data } = useGetFilesQuery(rowsPerPage, page * rowsPerPage, stringify(filter));
  const { data: filesData } = data || {};

  const files = filesData?.results.map((file) => {
    return { ...file, hasError: file.status === FILE_STATUS.ERROR};
  });

  const { data: userData } = useGetUsersQuery(stringify({search: searchFilters.uploadedBy}));

  const users = userData?.data?.map((c) => {
    return { id: c.id, label: c.display_name };
  }) || [];

  const filterOptions = {
    status: addSelectAllOption(Object.keys(FILE_STATUS_LABELS).map((key: string) => ({id: key, label: FILE_STATUS_LABELS[key]})), 'All Statuses'),
    uploadedBy: addSelectAllOption(sortOptions(users), 'All Uploaders'),
    category: addSelectAllOption(Object.keys(FILE_CATEGORY_LABELS).map((key: string) => ({id: key, label: FILE_CATEGORY_LABELS[key]})), 'All Categories')
  };

  const applyFilters = useCallback(() => {
    const query = mapSelectedFiltersToQuery(filterParams, search);
    setFilter(query);
    setPageAppliedFilters(FILTER_PAGE_SOURCE.FILE, query.search, filterParams);
    setFilterChangesNumber(calculateNumberOfFilterChanges(FILE_FILTER_CONFIG, filterParams));
  }, [ filterParams, search ]);

  const applySearch = () => {
    const query = {
      ...filter,
      search,
    };
    debounceFilters(query, appliedFiltersAndSearch);
  };

  useEffect(applyFilters, []);

  useEffect(applySearch, [search]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce((query, appliedFiltersAndSearch) => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.FILE, query.search, getAppliedFilters(appliedFiltersAndSearch));
    }, 300),
    []
  );

  return (
    <Layout title='Files'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.FILE}
        filterSetSource={FILTER_SET_SOURCE.FILE}
        filterConfig={FILE_FILTER_CONFIG}
        filterOptions={filterOptions}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        applyFilters={applyFilters}
        searchText={search}
        setSearchText={setSearch}
        searchParams={searchFilters}
        setSearchParams={setSearchFilters}
        filterChangesNumber={filterChangesNumber}
        setFilterChangesNumber={setFilterChangesNumber}>
        <AccessControl permissions={[FILE_PERMISSIONS.IMPORT]}>
          <ActionButton
            text='New File'
            startIcon={<FontAwesomeIcon icon={faPlus}/>}
            handleClick={() => setIsUploadFileModalOpen(true)}
            className={classes.headerButton}/>
          <ModalComponent
            message={'Upload file'}
            isOpen={isUploadFileModalOpen}
            modalWidth='xs'
            className={classes.uploadModalComponent}
            onCancel={() => setIsUploadFileModalOpen(false)}>
            <UploadFileModal onCancel={() => setIsUploadFileModalOpen(false)} />
          </ModalComponent>
        </AccessControl>
      </FilterComponent>
      <TableComponent
        columns={FilesTableColumns}
        isLoading={isFilesFetching}
        isSuccess={isSuccess}
        areRowsSelectable={false}
        isError={isError}
        tableData={files}
        rows={filesData?.count}
        page={page}
        setPage={setPage}
        pageRows={rowsPerPage}
        setPageRows={setRowsPerPage}
        renderMenu={(file: FileType) => {
          return (
            <FilesTableMenu file={file} />
          );
        }} />
    </Layout>
  );
};