import React from 'react';
import { Expression, ExpressionEditorConfig, Setter } from 'types';
import ExpressionEditor from './ExpressionEditor';

type SetterEditorProps = {
    setterState: Setter;
    setSetterState: (setter: Setter) => void;
    config: ExpressionEditorConfig;
    isReadOnly?:boolean
};

export const SetterEditor = ({ setterState, setSetterState, config, isReadOnly = false}: SetterEditorProps) => {

  const onExpresionUpdate = (expresion: Expression) => {
    const setter = {
      ...setterState
    };
    setter.action_data.expression = expresion;
    setSetterState(setter);
  };

  return (
    <ExpressionEditor
      resultType={config.actions.find(el => el.name === setterState.name).config.result_type}
      config={config}
      expression={setterState.action_data.expression}
      onUpdate={onExpresionUpdate}
      isReadOnly={isReadOnly}/>
  );
};